import React, { useEffect, useState} from 'react'
import '../styles/alertsreward.css'
import close_img from '../icons/close.png'
import loading from '../icons/loading.gif'



const AlertsRewardCommunity = ({setalertrewardcommunityStatus, info, address, myContract, resetInfo}) =>{
  const [data, setData] = useState([]);
  const [claimStatus, setClaimStatus] = useState(0);

  function closePage(){
    setalertrewardcommunityStatus(0);
  }

  async function getReward(){
    if(address.length > 0){
        try{
            const identityCoder = {
                "Address": {
                    "bits": address
                }
               };
          
            let result = await myContract.functions.get_community_bonus_list(identityCoder).get();
            // console.log(result);
            // console.log(result.value.len.toString());
            // console.log(result.value);
            if(result.value.length > 0){
              setData(result.value);
            }          
        }catch(e){
          console.log(e);
        }
    }
  }
  async function doClaim(data){
    if(address.length > 0){
      try{
        setClaimStatus(1);
        const contractCallGasLimit = 500_000;
        let result = await myContract.functions.claim_node_bonus(data).callParams(
          {
            // gasLimit: contractCallGasLimit,
          }
        ).call();
        let res = await result.waitForResult();
        if(res.transactionResult.status === 'success'){
          // getTokenBalance();
          resetInfo();
        }else{
          alert('Transaction Failed');
        }
        setClaimStatus(0);
      }catch(e){
          alert(e.toString());
          setClaimStatus(0);
      }
    }
  }

  useEffect ( ()=>{
    getReward();
    // console.log(info)
}, [address, info]);
    
    return(
      <div className='alert_con'>
        <div className='alert_list_child'>  
          <div className='alert_list_title'><img src={close_img} onClick={closePage}/></div>   
          <div className='alert_reward_contain'>
            <div className='alert_reward_title'>
              <li>Community Rewards</li>
            </div>
            {data.map(item => <div className='alert_reward_content' key={item.epoch}>
                <div className='alert_reward_content_epoch'>
                  <li>Epoch {item.epoch.toString()}</li>
                </div>
                <div className='alert_reward_content_reward'>
                  <li className='alert_reward_content_value'>Total Rewards: {((item.epoch_should_bonus.toString())/(10**9)).toFixed(5)} ETH</li>
                  {item.epoch_total_weight ? <li className='alert_reward_content_weight'>Total CPoints: {item.epoch_total_weight.toString()}</li> : <li className='alert_reward_content_weight'>Total CPoints: 0</li>}
                </div>
                {!item.my_epoch_weight ? (<div className='alert_reward_content_eligibleb'>
                  <li className='alert_reward_content_eligib'>You Are Not Eligible</li>
                </div>) : (
                !item.has_claimed ? (<div className='alert_reward_content_claim_contain'>
                  <div className='alert_reward_content_eligible'>
                    <li className='alert_reward_content_value_claim'>Your Rewards</li>
                    <li className='alert_reward_content_weight_claim'>{(((item.my_epoch_weight.toString()/item.epoch_total_weight.toString())*(item.epoch_should_bonus.toString()))/(10**9)).toFixed(5)} ETH</li>
                  </div>
                  <div className='alert_reward_content_button'>
                  {item.my_epoch_weight ? (item.my_epoch_weight.toString() > 0 && (claimStatus === 0 ? <li onClick={()=>doClaim(item.epoch.toString())}>Claim</li>: <li><img src={loading}/></li>)): ''}
                  </div>
                </div>
                ): (<div className='alert_reward_content_eligibleb'>
                  <li className='alert_reward_content_eligib'>Already Claimed</li>
                </div>))}
                
            </div>)}
            {data.length === 0 && <div className='alert_reward_list'> <li className='alert_reward_list_nodata'>No Data Yet</li></div>}
           
          </div> 
          
        </div>
      </div>
    )
  }

  export default AlertsRewardCommunity