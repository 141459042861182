import React, { useState, useEffect, useRef}  from 'react'
import '../styles/turnable.css'
// import p1 from '../icons/taurus2.png'
import pin_img2 from '../icons/pin2.png'
import pin_img1 from '../icons/pin1.png'
// import logo_img from '../icons/logo.png'
import { LuckyWheel } from '@lucky-canvas/react'
import Title from './title'
import { TAI64 } from 'tai64'
import loading from '../icons/loading.gif'
import AlertsSpin from './alertsSpin'


const Turntables = ({setFuel, fuel, rebirthStatus, setalertCONStatus, tokenBalance, setalertRebirthStatus, resetInfo, info, address, setAddress, myContract, setMyWallet, setMyContract, myname, setMyName}) => {
    
    
    const [spinStatus, setSpinStatus] = useState(0);
    const [dospinStatus, setDoSpinStatus] = useState(0);
    const [spinName, setSpinName] = useState('0d:0h:0m:0s');
    const [alertspinStatus, setalertspinStatus] = useState(0);
    const [rewardName, setRewardName] = useState('accelerate1');

    let timer=useRef();    

    const [blocks] = useState([
        { padding: '10px' },
        {
          padding: '10px',
          imgs: [{
            src: pin_img1,
            width: '100%',
            rotate: true
          }]
        }
      ])
    
    const [prizes] = useState([
        { title: 'accelerate1' },
        { title: 'banana' },
        { title: 'accelerate2' },
        { title: 'apple' },
        { title: 'accelerate3' },
        { title: 'universal card' },
        ])
    
    const [buttons] = useState([
        {
            radius: '45%',
            imgs: [{
            src: pin_img2,
            width: '50%',
            top: '-125%'
            }]
        }])
    const myLucky = useRef();

    function doTurntable(data){
        // setEndIndex(2);
        myLucky.current.play()
        setTimeout(() => {
         if(data == 1){
           myLucky.current.stop(3)
         }else if(data == 2){
           myLucky.current.stop(1)
         }else if(data == 3){
          myLucky.current.stop(0)
         }else if(data == 4){
          myLucky.current.stop(2)
         }else if(data == 5){
          myLucky.current.stop(4)
         }else if(data == 6){
          myLucky.current.stop(5)
         }
        
        }, 2500)

    }

    async function doSpin(){
      if(address.length > 0){
        if(info.length>0){ 
          // if(info[0] != 'None'){
          if(info[0]){
            if(rebirthStatus == 1){
              setalertRebirthStatus(1);
            }else{
               if(tokenBalance < 50){
                  setalertCONStatus(1);
               }else{
                  try{
                    setDoSpinStatus(1);
                    const _price = (50)*((10**9));
                    const contractCallGasLimit = 450_000;
                    let assetId = '0x6e8efa8e5fde3e677a0fdfe1f53e7fc077c19627ff447a0ff7254795dd908e41'; //token assetid
                    let result = await myContract.functions.lucky_turntable().callParams(
                      {
                        forward: [_price, assetId],
                        // gasLimit: contractCallGasLimit,
                      }
                    ).call();
                    let res = await result.waitForResult();
                    // console.log(res);
                    // console.log(res.value);
                    if(res.transactionResult.status === 'success'){
                        doTurntable(res.value);
                        // resetInfo();
                        // setDoSpinStatus(0);
                        
                    }else{
                      alert('Transaction Failed');
                      setDoSpinStatus(0);
    
                    }
                  }catch(e){
                      // console.log(e);
                      alert(e.toString());
                      setDoSpinStatus(0);
                      // alert('Transaction Failed', e);
                  }
               }
            }
          }else{
            alert('Please Mint Monster First!');
          }
        }else{
          alert('Please Mint Monster First!');
        }

      }else{
        alert('Please Connect Wallet First');
      }
    }

    const countDown = (expiretime) => {
      const nowTime = +new Date(); //获取當前时间的时间戳（单位毫秒）
      const times = parseInt(`${(expiretime - nowTime) / 1000}`); //把剩余时间毫秒数转化为秒
      if(times > 0){
          let d = parseInt(`${(times / 60 / 60 ) / 24}`); //计算天数 转化为整数
          let h = parseInt(`${(times / 60 / 60) % 24}`); //计算小时数 转化为整数
          let m = parseInt(`${(times / 60) % 60}`); //计算分钟数 转化为整数
          let s = parseInt(`${times % 60}`); //计算描述 转化为整数
      
          // console.log('d:', d, 'h:', h, 'm:', m, 's:', s) 
          if(d < 10){
          d = `0${d}`
          }
          if(h < 10){
          h = `0${h}`
          }
          if(m < 10){
          m = `0${m}`
          }
          if(s < 10){
          s = `0${s}`
          }
          let times_ = d + "d : " + h + "h : " + m + "m : " + s + "s";
      
          setSpinName(times_)
          if(times < 3){
              console.log('times:', times);
              setTimeout(() => {
                  setSpinStatus(1);    
              }, 1000);
          }
          
      }else{
        
          if(times > -3){
              console.log(spinStatus);
              setTimeout(() => {
                setSpinStatus(1)    
              }, 1000);
          }
      }
      
  }
  function checkStatus(_claimtime){
    const nowTime = +new Date();
    if(_claimtime -nowTime > 0){
        setTimeout(() => {
          setSpinStatus(0)  
        }, 0);
    }else{
        setTimeout(() => {
          setSpinStatus(1)  
        }, 0);
    }

  }

    useEffect ( ()=>{
      // console.log(info)
      if(address.length > 0){
          if(info.length>0){           
              
              // const expire = 1703246400000;
              let turntabletime = 1703246400000;
              // if(info[0] != 'None'){
                if(info[0]){
                  turntabletime=((TAI64.fromHexString((info[0].turntabletime).toString(16)).toUnix()) + 60*60*6)*1000;
              }
              // console.log(turntabletime);
              checkStatus(turntabletime);
              timer.current = setInterval(()=>{
                  countDown(turntabletime);
              }, 1000);
          }
      }

      return()=>{
          // console.log('leave spin page------------');
          clearInterval(timer.current);
        }
      
    }, [address, info]);

    return (
        
        <div className='turntable_page'>
          <div className='turntable_contain'>
            <Title setFuel={setFuel} fuel={fuel}  address={address} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName}/>
            <div className='turntable_page_content'>
              <LuckyWheel
                ref={myLucky}
                width="300px"
                height="300px"
                blocks={blocks}
                prizes={prizes}
                buttons={buttons}
                onEnd={prize => { // when end, will call
                    // alert('Congratulations! You Got ' + prize.title)
                    setRewardName(prize.title);
                    setalertspinStatus(1);
                }}
              />
              <div className='turntable_button'>
              {spinStatus === 0 ? <li >Spin In {spinName}</li> : (dospinStatus === 0 ? <li onClick={doSpin}>50CON to Spin</li>: <li><img src={loading}/></li>)}
              </div>
              {alertspinStatus === 1 ? <AlertsSpin setalertspinStatus={setalertspinStatus} resetInfo={resetInfo} setDoSpinStatus={setDoSpinStatus} rewardName={rewardName}/>: ''}
            </div>
          </div>
        </div>
    )

}

export default Turntables