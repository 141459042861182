import React, { useEffect, useState} from 'react'
import '../styles/alertsmint.css'
import close_img from '../icons/close.png'
import loading from '../icons/loading.gif'



const AlertsMint = ({myContract, address, inviter, resetInfo, setMintAlertStatus}) =>{
    const [mintStatus, setMintStatus] = useState(0);
    const [inputValue, setInputValue] = useState(inviter);

    function closePage(){
      setMintAlertStatus(0);
    }

    async function doMint(){
      
      if(address.length > 0){
        if(inputValue.length == 66 || inputValue == ''){
          try{
              setMintStatus(1)
              const _price = (0.0005)*((10**9));
              const contractCallGasLimit = 1300_000;
              let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
              // console.log(inviter)
              let _inviter = inputValue;
              if(inputValue == ''){
                _inviter = inviter;
              }
              const identityCoder = {
                "Address": {
                    "bits": _inviter
                }
              };
              let result = await myContract.functions.mint_monster(identityCoder).callParams({
                forward: [_price, baseAssetId],
                // gasLimit: contractCallGasLimit,
              }).call();
              let res = await result.waitForResult();
              if(res.transactionResult.status === 'success'){
                // setMintMonsterStatus(0);
                resetInfo();
                setMintAlertStatus(0);
              }else{
                // setMintStatus(3);
                alert('Transaction Failed');

              }
              setMintStatus(0);
          }catch(e){
            //   console.log(e);
            //   setMintStatus(3);
            alert(e.toString());
            setMintStatus(0);
          }
        }else{
          alert('Please Input Valid Inviter');
        }

      }else{
        alert('Please Connect Wallet First');
      }

    }
    
    return(
      <div className='alert_con'>
        <div className='alert_child'>
            <div className='alert_feed_title'><img src={close_img} onClick={closePage}/></div> 
            <li className='alert_mint_title'>Mint Your Monster</li>
            <div className='alert_mint_price'>
                <li className='alert_mint_price_name'>Price:</li>
                <li className='alert_mint_price_value'>0.0005 ETH</li>
            </div>
            <div className='alert_mint_inviter'>
                <li className='alert_mint_price_name'>Inviter:</li>
                  <div className='alert_mint_price_value'>
                    <input placeholder={inviter}
                            onChange={e => setInputValue(e.target.value)}/>
                  </div>
            </div>     
            <div className='alert_mint_button_contain'> 
              {mintStatus==0 ? (<li className='alert_mint_button' onClick={doMint}>Mint</li>): (<div className='alert_mint_button'><img src={loading}/></div>)}
            </div>    
        </div>
      </div>
    )
  }

  export default AlertsMint