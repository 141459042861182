import React, { useState, useEffect, useRef}  from 'react'
import '../styles/bonuspool.css'
import Title from './title'
import BonusCombine from './bonus_combine'
import BonusInvite from './bonus_invite'
import BonusCommunity from './bonus_community'


const BonusPool = ({setFuel, fuel, setalertCONStatus, setalertRebirthStatus, address, setAddress, myContract, setMyContract, setMyWallet, myname, setMyName, info, resetInfo}) => {

    const [choose, setChoose] = useState(1);

    function clickBonus(data){
      setChoose(data);
    }
    // useEffect ( ()=>{
        
    // }, [address, info]);

    return (
        <div className='bonus_pool_page'>
          <div className='pool_contain'>
            <Title setFuel={setFuel} fuel={fuel}  address={address} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName}/>
          </div>
          <div className='pool_tab'>
            <li className= {choose === 1 ? 'pool_tab_choose' : 'pool_tab_combinepool'} onClick={()=>clickBonus(1)}>Combine Pool</li>
            <li className= {choose === 2 ? 'pool_tab_choose' : 'pool_tab_invitepool'} onClick={()=>clickBonus(2)}>Invite Pool</li>
            <li className= {choose === 3 ? 'pool_tab_choose' : 'pool_tab_communitypool'} onClick={()=>clickBonus(3)}>Community Pool</li>
          </div>
            {choose === 1 && <BonusCombine resetInfo={resetInfo} info={info} address={address} setAddress={setAddress} myContract={myContract} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName} />}
            {choose === 2 && <BonusInvite resetInfo={resetInfo} info={info} address={address} setAddress={setAddress} myContract={myContract} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName} />}
            {choose === 3 && <BonusCommunity resetInfo={resetInfo} info={info} address={address} setAddress={setAddress} myContract={myContract} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName} />}
        </div>        
        
    )

}

export default BonusPool