import React, { useEffect, useState} from 'react'
import '../styles/alertsconstellaswap.css'
import close_img from '../icons/close.png'
import plus from '../icons/add.png'
import reduce from '../icons/sub.png'
import loading from '../icons/loading.gif'
import aries_img2 from '../icons/constellation/aries1.png'
import taurus_img2 from '../icons/constellation/taurus1.png'
import gemini_img2 from '../icons/constellation/gemini1.png'
import cancer_img2 from '../icons/constellation/cancer1.png'
import leo_img2 from '../icons/constellation/leo1.png'
import virgo_img2 from '../icons/constellation/virgo1.png'
import libra_img2 from '../icons/constellation/libra1.png'
import scorpio_img2 from '../icons/constellation/scorpio1.png'
import sagittarius_img2 from '../icons/constellation/sagittarius1.png'
import capricornus_img2 from '../icons/constellation/capricornus1.png'
import aquarius_img2 from '../icons/constellation/aquarius1.png'
import pisces_img2 from '../icons/constellation/pisces1.png'
import zodiac_img2 from '../icons/constellation/zodiac1.png'
import universal_img2 from '../icons/constellation/universal1.png'



const AlertsConstellaSwap = ({rebirthStatus, setalertRebirthStatus, myContract, address, resetInfo, setConstellaSwapAlertStatus, chooseCardName, chooseCardNum}) =>{
    const [swapStatus, setSwapStatus] = useState(0);
    const [Nums, setNums] = useState(1);
    const [cardType, setCardType] = useState(1);
    const [cardImg, setCardImg] = useState(1);
    const [selectCardValue, setSelectCardValue] = useState(1);

    function closePage(){
      setConstellaSwapAlertStatus(0);
    }

    function plusing() {
      if(Nums < chooseCardNum){
          setNums(Nums + 1)
      }
  }
  function reducing(){
      if(Nums > 1){
          setNums(Nums - 1)
      }
  }

  function dealCard() {
    if(chooseCardName === 'Aries') {
      setCardType(1);
      setCardImg(aries_img2);
    }else if(chooseCardName === 'Taurus'){
      setCardType(2);
      setCardImg(taurus_img2);
    }else if(chooseCardName === 'Gemini'){
      setCardType(3);
      setCardImg(gemini_img2);
    }else if(chooseCardName === 'Cancer'){
      setCardType(4);
      setCardImg(cancer_img2);
    }else if(chooseCardName === 'Leo'){
      setCardType(5);
      setCardImg(leo_img2);
    }else if(chooseCardName === 'Virgo'){
      setCardType(6);
      setCardImg(virgo_img2);
    }else if(chooseCardName === 'Libra'){
      setCardType(7);
      setCardImg(libra_img2);
    }else if(chooseCardName === 'Scorpio'){
      setCardType(8);
      setCardImg(scorpio_img2);
    }else if(chooseCardName === 'Sagittarius'){
      setCardType(9);
      setCardImg(sagittarius_img2);
    }else if(chooseCardName === 'Capricornus'){
      setCardType(10);
      setCardImg(capricornus_img2);
    }else if(chooseCardName === 'Aquarius'){
      setCardType(11);
      setCardImg(aquarius_img2);
    }else if(chooseCardName === 'Pisces'){
      setCardType(12);
      setCardImg(pisces_img2);
    }else if(chooseCardName === 'Universal'){
      // setCardType(12);
      setCardImg(universal_img2);
    }

  }

  function handerCardChange(e){
    // console.log(e.target.value)
    setSelectCardValue(e.target.value);
  }

  async function doSwap(){
      
      if(address.length > 0){
        if(rebirthStatus == 1){
          setalertRebirthStatus(1);
        }else{
          try{
            setSwapStatus(1)
            const contractCallGasLimit = 500_000;              
            let result = await myContract.functions.swap_constellation_to_coin(cardType, Nums).callParams({
              // gasLimit: contractCallGasLimit,
            }).call();
            let res = await result.waitForResult();
            if(res.transactionResult.status === 'success'){
              resetInfo();
              setConstellaSwapAlertStatus(0);
            }else{
              // setMintStatus(3);
              alert('Transaction Failed');

            }
            setSwapStatus(0);
          }catch(e){
            //   console.log(e);
            //   setMintStatus(3);
            alert(e.toString());
            setSwapStatus(0);
          }
        }

      }else{
        alert('Please Connect Wallet First');
      }

  }
  
  async function doSwapUniversal(){
      
      if(address.length > 0){
        if(rebirthStatus == 1){
          setalertRebirthStatus(1);
        }else{
          try{
              setSwapStatus(1)
              const contractCallGasLimit = 500_000;              
              let result = await myContract.functions.use_universal_card(selectCardValue).callParams({
                // gasLimit: contractCallGasLimit,
              }).call();
              let res = await result.waitForResult();
              if(res.transactionResult.status === 'success'){
                resetInfo();
                setConstellaSwapAlertStatus(0);
              }else{
                // setMintStatus(3);
                alert('Transaction Failed');

              }
              setSwapStatus(0);
          }catch(e){
            //   console.log(e);
            //   setMintStatus(3);
            alert(e.toString());
            setSwapStatus(0);
          }
        }

      }else{
        alert('Please Connect Wallet First');
      }

    }

    useEffect ( ()=>{
      dealCard();
    }, [address]);
    
    return(
      <div className='alert_con'>
        <div className='alert_child'>
            <div className='alert_feed_title'><img src={close_img} onClick={closePage}/></div> 
            <li className='alert_mint_title'>Swap Your Constellation Cards</li>
            <div className='alert_mint_price'>
                <li className='swap_card_name_title'>Card:</li>
                <div className='swap_card_name'><img src={cardImg}/><li>{chooseCardName}</li></div>
                <li className='swap_card_value'>Card Total Amounts: {chooseCardNum}</li>
            </div>
            {chooseCardName !== 'Universal' &&
            <div className='swap_card_contain'>
                <div className='swap_card_num'>
                    <li className='swap_card_num_title'>Swap Amounts:</li>
                    <div className='swap_card_num_contain'>
                      <img src={reduce} onClick={reducing}></img>
                      <li className='swap_card_num_value'>{Nums}</li>
                      <img src={plus} onClick={plusing}></img>
                    </div>
                </div>
                <div className='alert_card_token_contain'>
                    <li className='alert_card_token'>Get CON: {chooseCardNum * 50}</li>
                </div>     
                <div className='alert_mint_button_contain'> 
                  {swapStatus==0 ? (<li className='alert_mint_button' onClick={doSwap}>Swap</li>): (<div className='alert_mint_button'><img src={loading}/></div>)}
                </div>   
            </div> 
            }
            {chooseCardName === 'Universal' &&
            <div className='swap_card_contain'>
                <div className='swap_card_num'>
                    <li className='swap_card_num_title'>Swap To :</li>
                    <select name = "dropdown" value={selectCardValue}
                                    onChange={(e)=>handerCardChange(e)}>
                            <option value = "1" >Aries </option>
                            <option value = "2">Taurus </option>
                            <option value = "3">Gemini </option>
                            <option value = "4">Cancer </option>
                            <option value = "5">Leo </option>
                            <option value = "6">Virgo </option>
                            <option value = "7">Libra </option>
                            <option value = "8">Scorpio </option>
                            <option value = "9">Sagittarius </option>
                            <option value = "10">Capricornus </option>
                            <option value = "11">Aquarius </option>
                            <option value = "12">Pisces </option>
                    </select>
                </div>
                    
                <div className='alert_mint_button_contain'> 
                  {swapStatus==0 ? (<li className='alert_mint_button' onClick={doSwapUniversal}>Swap</li>): (<div className='alert_mint_button'><img src={loading}/></div>)}
                </div>   
            </div> 
            }
        </div>
      </div>
    )
  }

  export default AlertsConstellaSwap