import React, { useEffect, useState} from 'react'
import '../styles/alerts.css'



const Alerts = () =>{
    
    return(
      <div className='alert_con'>
        <div className='alert_child'>
            <div className='alert_child_title'>
                <li>alert</li>
            </div>
            <div className='alert_child_address'>
                <li>recomend:</li>
                <p>address</p>
            </div>
            <div className='alert_child_button'>
            
              <li >Confirm</li>
                
            </div>

              
        </div>
      </div>
    )
  }

  export default Alerts