import React, { useEffect, useState} from 'react'
import '../styles/alertswallet.css'
import close_img from '../icons/close.png'
import fuel_logo from '../icons/fuellogo.png'
import fuelet_logo from '../icons/fueletlogo.png'
import loading from '../icons/loading.gif'
import mynameAbi from "../json/name.json";
import mygameAbi from "../json/game.json";
import { Fuel, Contract } from 'fuels';
import { FuelWalletConnector, FuelWalletDevelopmentConnector, FueletWalletConnector, WalletConnectConnector, BakoSafeConnector, SolanaConnector } from '@fuels/connectors';



const AlertsWallet = ({ setAlertWalletStatus, setAddress, setMyContract, setMyWallet, setFuel}) =>{

    // const namecontractId= '0xa09c043557bf12021893915c370457464fe6a308975a31156d2860c871726284';
    // const gamecontractId = '0x3bf80f6bb117dc2983d5ade428021db33875776e49b4712561147609bde1fffb';
    const gamecontractId = '0xbafa758588a660733745b386f95bdabb229f51147561a0e37dacd3e71ed94a2f';
    

    function closePage(){
      setAlertWalletStatus(0);
    }

    async function connectAccount(walletname) {
      
        try{
          
          let  fuel = new Fuel({
              connectors: [
                new FuelWalletConnector(),
                new FueletWalletConnector(),
                // new BakoSafeConnector(),
              ],
            });

          // console.log(fuel)

          await fuel.init();
          
          await fuel.selectConnector(walletname);
          
          
          let res = await fuel.connect();
          // console.log(res);
          setFuel(fuel);

            if(res){
              const currentAccount = await fuel.currentAccount();
              const wallet = await fuel.getWallet(currentAccount);
              setMyWallet(wallet);
              setAddress(wallet.address.toB256());  

              const gameContract = new Contract(gamecontractId, mygameAbi, wallet);
              setMyContract(gameContract);
              setAlertWalletStatus(0);
            
            }else{
              console.log('not connected');
            }
          
        }catch(e){
           console.log(e)
          alert('launch wallet error, please install ' + walletname +' and conncet it');
        }
        
    }

    
    return(
      <div className='alert_con'>
        <div className='alert_child_wallet'>
            <div className='alert_wallet_title'>
              <li>Connect Wallet</li>
              <img src={close_img} onClick={closePage}/>
            </div> 
            <div className='alert_wallet_contain' onClick={()=>connectAccount("Fuel Wallet")}>
              <img src={fuel_logo}/>
              <li>Fuel Wallet</li>
            </div>
            <div className='alert_wallet_contain' onClick={()=>connectAccount("Fuelet Wallet")}>
              <img src={fuelet_logo}/>
              <li>Fuelet Wallet</li>
            </div>
            {/* <div className='alert_wallet_contain' onClick={()=>connectAccount("Bako Safe")}>
              <img src={fuel_logo}/>
              <li>Bako Safe</li>
            </div> */}
            
        </div>
      </div>
    )
  }

  export default AlertsWallet