import React, { useEffect, useState} from 'react'
import '../styles/alertsspin.css'
import close_img from '../icons/close.png'
import zodiac_img2 from '../icons/constellation/zodiac1.png'




const AlertsZodiac = ({setalertZodiacStatus}) =>{


  function closePage(){
    setalertZodiacStatus(0);
  }

    
    return(
      <div className='alert_con'>
        <div className='alert_list_child'>  
          <div className='alert_spin_title'><img src={close_img} onClick={closePage}/></div>   
          <div className='alert_constella_contain'>
            <li className='alert_constella_contain_title'>Congratulations!</li>
            <div className='alert_constella_contain_content'>You Got One <img src={zodiac_img2}/> Zodiac Card</div>
          </div>
          
        </div>
      </div>
    )
  }

  export default AlertsZodiac