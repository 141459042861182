import React, { useState, useEffect}  from 'react'
import '../styles/monster.css'
import logo_img from '../icons/logo.png'
import fuel_img from '../icons/fuellogo.png'
import twitter from '../icons/twitter.png'
// import { FuelWalletConnector } from '@fuels/connectors';
import { useNavigate, useLocation } from 'react-router-dom';
import AlertsWallet from './alertswallet'

const Title = ({setFuel, fuel, address, setAddress, setMyContract, setMyWallet, myname, setMyName }) => {

    // const [fueladdress, setFuelAddress] = useState([]);
    const isConnected = Boolean(address[0]);
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [choose, setChoose] = useState(pathname);
    const [alertWalletStatus, setAlertWalletStatus] = useState(0);

   
    // const sdk = new Fuel();


    const clickTurntable = () =>{
        setChoose('/turntable')
        navigate('/turntable')
    }
    const clickHome = () =>{
        setChoose('/')
        navigate('/')
    }
    const clickBattle = () =>{
        setChoose('/battle')
        navigate('/battle')
    }
    const clickBonus = () =>{
        setChoose('/bonus')
        navigate('/bonus')
    }
    
    function HandString(){
         
        // let str = fueladdress;
        let str = address;
        if(myname.length >0){
          str = myname;
          if(myname.length > 20){
            let pre = str.substr(0,8);
            let end = str.substr(str.length - 7, 7);
            return pre+'...'+ end;
          }else{
            return myname;
          }
          
        }else{
          let pre = str.substr(0,5);
          let end = str.substr(str.length - 4, 4);
          return pre+'...'+ end;
        }
    }

    function redirectToPage() {
      window.open('https://constellation.fun', '_blank');
  }

    function connectWallet(){
      setAlertWalletStatus(1);
    }

    async function disConnect(){
        setAddress([]);
        await fuel.disconnect();
    }

    return (
        <div className='monster_title'>
                    <div className='monster_logo' onClick={()=>redirectToPage()}>
                        <img src={logo_img}/>
                        <li>Constellation Battle</li>
                        {/* <li><a href='https://x.com/constellationba' target="_blank"><img className='twitter' src={twitter}/></a></li> */}
                    </div>
                    <div className='title_tab'>
                      <div className='title_item' onClick={clickHome}>
                      {choose == '/' ? 
                        <li className='choose_item'>Monster</li>:
                        <li className='no_choose_item'>Monster</li>}
                      </div>
                      <div className='title_item' onClick={clickTurntable}>
                      {choose == '/turntable' ?
                        <li className='choose_item'>Spin</li>:
                        <li className='no_choose_item'>Spin</li>}
                        </div>
                      <div className='title_item' onClick={clickBattle}>
                      {choose == '/battle' ?
                        <li className='choose_item'>Battle</li>:
                        <li className='no_choose_item'>Battle</li>}
                        </div>
                      <div className='title_item' onClick={clickBonus}>
                      {choose == '/bonus' ?
                        <li className='choose_item'>Bonus</li>:
                        <li className='no_choose_item'>Bonus</li>}
                        </div>
                      <div className='title_network'>
                        <img src={fuel_img}/>
                        <li className='network_name'>Fuel Testnet</li>
                      </div>
                    </div>
                    <div className='monster_connect'>
                        {isConnected ? <li onClick={disConnect}><HandString /></li> : <li onClick={connectWallet}>Connect Wallet</li>}
                        
                    </div>
                    {alertWalletStatus === 0 ? '' : <AlertsWallet setFuel={setFuel} setAlertWalletStatus={setAlertWalletStatus} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet}/>}
        </div>
    )
}

export default Title