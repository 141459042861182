import React, { useState, useEffect, useRef}  from 'react'
import '../styles/bonus.css'
import happy_img from '../icons/happy.png'
import unhappy_img from '../icons/unhappy.png'
import record_img from '../icons/record.png'
import loading from '../icons/loading.gif'
import eth_img from '../icons/eth.png'
// import Title from './title'
import AlertsRewardCommunity from './alertsreward_community'
import AlertsRecordCpoints from './alertsrecord_cpoints'
import axios from 'axios'

const CommunityPool =({address, info, resetInfo, myContract}) =>{
    const bonusRef = useRef(); //创建useRef
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [data, setData] = useState([]);
    const [mylevel, setMylevel] = useState(0);
    const [docommunityBattleStatus, setdocommunityBattleStatus] = useState(0);
    const [chooseCommunity, setchooseCommunity] = useState('');
    const [onBattle, setOnBattle] = useState(0);

    function HandString(str){
      let length = str.length;
      let pre;
      if(str.length > 20){
        pre = str.substr(0,9);
        let end = str.substr(length - 10, 10);
        return pre+'...'+ end;
      }else{
        return str;
      }
      // let pre = str.substr(0,5);
      
      
    }
    async function doCommunityBattle(data){
      if(address.length > 0){
        setchooseCommunity(data);
        try{
          setdocommunityBattleStatus(1);
          const contractCallGasLimit = 500_000;
          const identityCoder = {
            "Address": {
              "bits": data
            }
           };
          let result = await myContract.functions.launch_community_battle(identityCoder).callParams(
            {
              // gasLimit: contractCallGasLimit,
            }
          ).call();
          let res = await result.waitForResult();
          if(res.transactionResult.status === 'success'){
            resetInfo();
          }else{
            alert('Transaction Failed');
          }
          setdocommunityBattleStatus(0);
        }catch(e){
            alert(e.toString());
            setdocommunityBattleStatus(0);
        }
      }

    }

    const getDatas = async(_pages, _epoch) =>{
      
      try{
      //   console.log(epoch)
        // const res = await axios.get('http://localhost:3001/getnoderecord', {
          const res = await axios.get('https://api.constellation.fun/getnoderecord', {
            params: {
              page_num: _pages,
              page_size: 10,
              epoch: _epoch
            }
        })
      //   console.log(res)
        if(res.status == 200){
          if(res.data.paging.total%res.data.paging.page_size == 0){
            if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size))
            }
          }else{
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size) + 1)
          }
          let arr = []
          for(let i = 0; i < res.data.data.length; i++){
            arr.push({id: (res.data.paging.page_num - 1)*10 + i, name: res.data.data[i].name, node: res.data.data[i].node, level: res.data.data[i].level, cpoints: res.data.data[i].CPOINTS})
          }
          if(_pages == 1){
            setData(arr)
          }else{
            setData(data.concat(arr))
          }
          // console.log(_pages);
          // console.log(data);
          
         }
      }catch(e){
        console.log(e)
      }
    }
  
    const handleScroll = () =>{
      let res=bonusRef.current.scrollHeight - bonusRef.current.clientHeight- bonusRef.current.scrollTop;
      // console.log(res)
      if (res>1) {
            //未到底
        } else {
            //已到底部
            // console.log('to bottom');
            if(page < totalPage){
              if(address.length > 0){
                getDatas(page+1, info[4].epoch.toString())
                setPage(page+1)
              }
            }
      }
    }

    useEffect ( ()=>{
      if(address.length > 0){
          if(info.length > 0){
              if(info[4]){
                  getDatas(page, info[4].epoch.toString());
                if(info[4].my_node_level){
                  setMylevel(info[4].my_node_level.toString());
                }
                if(info[4].my_communitybattle_epoch_pair){
                  setOnBattle(1);
                }
              }
          }
      }
      
  }, [address, info]);

  if(data.length > 0){
    return (
      <div className='bonus_list' ref={bonusRef} onScroll={handleScroll}>
          <div className='bonus_list_title'>
              <li>Community</li>
              <li>Level</li>
              <li>CPoints</li>
              <div className='bonus_community_operate'>Operate</div>
          </div>
        {data.map(item => <div className='bonus_list_detail' key={item.id}>
          
              <li>{HandString(item.name)}</li>
              <li>{item.level}</li>
              <li>{item.cpoints ? item.cpoints : 0}</li>
              {(mylevel == item.level && item.node != address && onBattle === 0) ? (docommunityBattleStatus === 1 && chooseCommunity == item.node ? <div className='bonus_community_button'><img src={loading}/></div> : <div className='bonus_community_button' onClick={()=>doCommunityBattle(item.node)}>Battle</div>) : <div className='bonus_community_button_no'></div>}
          </div>)}
        
      </div>
    )
  }else{
    return (
      <div className='bonus_list'> <li className='bonus_list_nodata'>No Data Yet</li></div>
    )
  }
}

const CommunityBattle =({address, info}) =>{
      const bonusRef = useRef(); //创建useRef
      const [page, setPage] = useState(1);
      const [totalPage, setTotalPage] = useState(1);
      const [data, setData] = useState([]);
      

      function HandString(str){
        let length = str.length;
        let pre;
        if(str.length > 20){
          pre = str.substr(0,9);
          let end = str.substr(length - 10, 10);
          return pre+'...'+ end;
        }else{
          return str;
        }
        // let pre = str.substr(0,5);
        
        
      }

      const getDatas = async(_pages, _epoch) =>{
        
        try{
        //   console.log(epoch)
          // const res = await axios.get('http://localhost:3001/getcommunitybattle', {
            const res = await axios.get('https://api.constellation.fun/getcommunitybattle', {
              params: {
                page_num: _pages,
                page_size: 10,
                epoch: _epoch
              }
          })
        //   console.log(res)
          if(res.status == 200){
            if(res.data.paging.total%res.data.paging.page_size == 0){
              if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
                setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size))
              }
            }else{
                setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size) + 1)
            }
            let arr = []
            for(let i = 0; i < res.data.data.length; i++){
              arr.push({id: (res.data.paging.page_num - 1)*10 + i, challengername: res.data.data[i].challengername, challengedname: res.data.data[i].challengedname, challengercpoints: res.data.data[i].challengercpoints, challengedcpoints: res.data.data[i].challengedcpoints})
            }
            if(_pages == 1){
              setData(arr)
            }else{
              setData(data.concat(arr))
            }
            // console.log(_pages);
            // console.log(data);
            
          }
        }catch(e){
          console.log(e)
        }
      }

      const handleScroll = () =>{
        let res=bonusRef.current.scrollHeight - bonusRef.current.clientHeight- bonusRef.current.scrollTop;
        // console.log(res)
        if (res>1) {
              //未到底
          } else {
              //已到底部
              // console.log('to bottom');
              if(page < totalPage){
                if(address.length > 0){
                  getDatas(page+1, info[4].epoch.toString())
                  setPage(page+1)
                }
              }
        }
      }

      useEffect ( ()=>{
        if(address.length > 0){
            if(info.length > 0){
                if(info[4]){
                    getDatas(page, info[4].epoch.toString());
                }
            }
        }
        
    }, [address, info]);

    if(data.length > 0){
      return (
        <div className='bonus_list' ref={bonusRef} onScroll={handleScroll}>
            <div className='bonus_list_title'>
                <li>Challenger</li>
                <li></li>
                <li>Challenged</li>
            </div>
          {data.map(item => <div className='bonus_list_detail' key={item.id}>
            
                <li>{HandString(item.challengername)}({item.challengercpoints ? item.challengercpoints : 0} CPoints)</li>
                <li>VS</li>
                <li>{HandString(item.challengedname)}({item.challengedcpoints ? item.challengedcpoints: 0} CPoints)</li>
            </div>)}
          
        </div>
      )
    }else{
      return (
        <div className='bonus_list'> <li className='bonus_list_nodata'>No Data Yet</li></div>
      )
    }
}

const BonusCommunity = ({address, setAddress, myContract, setMyContract, setMyWallet, myname, setMyName, info, resetInfo}) => {

    const [alertrewardcommunityStatus, setalertrewardcommunityStatus] = useState(0);
    const [alertrecordCpointsStatus, setalertrecordCpointsStatus] = useState(0);
    const [shouldReward, setShouldReward] = useState(0);
    const [totalWeight, setTotalWeight] = useState(0);
    const [myWeight, setMyWeight] = useState(0);
    const [epoch, setEpoch] = useState(1);
    const [eligible, setEligible] = useState(false);
    const [choose, setChoose] = useState(1);
    const [nodeName, setNodeName] = useState('');
    const [inBattle, setInBattle] = useState(0);

    function disPlayReward(){
      setalertrewardcommunityStatus(1);
    }

    function getBonus(){
        if(address.length > 0){
            if(info.length > 0){
                if(info[7]){
                    // if(info[5].my_share_bonus != 'None'){
                      if(info[4].my_node_member_num){
                        setEligible(true);
                      }
                      if(info[7].my_cpoints){
                        setMyWeight(info[7].my_cpoints.toString());
                      }
                    // if(info[5].epoch_total_share != 'None'){
                      if(info[7].community_epoch_total_cpoints){
                        setTotalWeight(info[7].community_epoch_total_cpoints.toString());
                        // console.log(info[5].epoch_total_share.toString());
                    }
                    setShouldReward(info[7].community_epoch_should_bonus.toString());
                    // console.log(info[7].left_bonus.toString());
                }
                if(info[4]){
                    setEpoch(info[4].epoch.toString());
                    if(info[4].node_name){
                      setNodeName(info[4].node_name);
                    }
                    if(info[4].my_communitybattle_epoch_pair){
                      setInBattle(1);
                    }
                }
            }
        }
    }

    function doChoose(data){
      if(data === 1){
        setChoose(1);
      }else if(data === 2){
        setChoose(2);
      }
    }
    function disPlayRecord(){
      setalertrecordCpointsStatus(1);
    }

    useEffect ( ()=>{
        if(address.length > 0){
            getBonus();
        }
        
    }, [address, info]);

    return (
        <div className='bonus_page'>
            
            {/* <Title  address={address} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName}/> */}
            <div className='bonus_img'>
                  <div className='bonus_pool_label'>
                    <li className='bonus_pool_num'>{(shouldReward/(10**9)).toFixed(5)} ETH</li>
                    <li className='bonus_pool_name'>Community Pool</li>
                  </div>
                  <img className='bonus_pool_eth' src={eth_img}/>
            </div>
            <div className='bonus_contain'>
              <div className='bonus_top'>
                  <div className='bonus_top_left'>
                      <li>Current: Epoch {epoch}</li>
                      {/* <li>End In: 0d:0h:0m:0s</li> */}
                  </div>
                  <div className='bonus_top_right'>
                      <img src={record_img} onClick={disPlayReward}/>
                  </div>
              </div>
              
              {eligible ? 
              <div className='bonus_mid_eligible'>
                      {inBattle === 0 ? <li>{nodeName}, You Are Eligible</li> : <li>{nodeName}, You Are In Battle</li>}
                      <img src={happy_img}/>
              </div> : <div className='bonus_mid_eligible'>
                      <li>You Not Eligible</li>
                      <img src={unhappy_img}/>
              </div>}
              <div className='bonus_mid'>
                      <div className='bonus_mid_left'>My CPoints: {myWeight} <img className='bonus_mid_left_record' src={record_img} onClick={disPlayRecord}/></div>
                      <div className='bonus_community_choose'>
                        <div className={choose === 1 ? 'bonus_community_pool_choose':'bonus_community_pool'} onClick={()=>doChoose(1)}>Pool</div>
                        <div className={choose === 2 ? 'bonus_community_pool_choose':'bonus_community_battle'} onClick={()=>doChoose(2)}>Battle</div>
                      </div>
                      <li className='bonus_mid_right'>Total CPoints: {totalWeight}</li>
              </div>
              {choose === 1 && <CommunityPool info={info} address={address} resetInfo={resetInfo} myContract={myContract}/>}
              {choose === 2 && <CommunityBattle info={info} address={address}/>}
              {alertrewardcommunityStatus === 0 ? '' : <AlertsRewardCommunity setalertrewardcommunityStatus={setalertrewardcommunityStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
          </div>
          {alertrecordCpointsStatus === 0 ? '' : <AlertsRecordCpoints setalertrecordCpointsStatus={setalertrecordCpointsStatus} info={info} address={address}/>}
        </div>        
        
    )

}

export default BonusCommunity