import React, { useEffect, useState} from 'react'
import close_img from '../icons/close.png'
import invite_img from '../icons/invite.png'
import record_img from '../icons/record.png'
import copy_img from '../icons/copy.png'
import community_img from '../icons/community.png'
import loading from '../icons/loading.gif'
import '../styles/alertscommunity.css'
import AlertsRecordInvite from './alertsrecord_invite'
import AlertsRecordCommunity from './alertsrecord_community'
import copy from  'copy-to-clipboard'


const AlertsCommunity = ({setalertprofileStatus, info, address, myContract, resetInfo}) =>{
  const [nodeEligible, setNodeEligible] = useState(0);
  const [nodeMemberNum, setNodeMemberNum] = useState(0);
  const [nodeLevel, setNodeLevel] = useState(0);
  const [inviteEligible, setInviteEligible] = useState(0);
  const [inviteNum, setInviteNum] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [beNodeStatus, setBeNodeStatus] = useState(0);
  const [nodeName, setNodeName] = useState('');
  const [alertRecordInviteStatus, setAlertRecordInviteStatus] = useState(0);
  const [alertRecordCommunityStatus, setAlertRecordCommunityStatus] = useState(0);

  function closePage(){
    setalertprofileStatus(0);
  }

  const handCopy = () =>{
    if(address.length > 0){
        copy('https://app.constellation.fun/?ref=' + address);
        alert("copy success");
    }
    
}

  function HandString(str){
    let length = str.length;
    let pre;
    if(str.length == 64){
        pre = str.substr(0,2) + '00' + str.substr(2,1);
        let end = str.substr(length - 6, 6);
        return pre+'...'+ end;
  
    }else if(str.length > 10){
        pre = str.substr(0,5);
        let end = str.substr(length - 6, 6);
        return pre+'...'+ end;
    }else{
      return str
    }
    // let pre = str.substr(0,5);
    
  }

  function dealInit() {
    if(info.length>0){
      if(info[4].my_invite_eligible){
        setInviteEligible(1);
        if(info[4].my_invite_total_num){
          setInviteNum(info[4].my_invite_total_num.toString());
        }
      }

      if(info[4].my_node_member_num){
        setNodeEligible(1);
        setNodeMemberNum(info[4].my_node_member_num.toString());
      }
      if(info[4].my_node_level){
        setNodeLevel(info[4].my_node_level.toString());
      }
      if(info[4].node_name){
        setNodeName(info[4].node_name);
      }

    }
  }
  async function beNode(){
    if(info.length>0){
      if(inputValue === ''){
        alert('Please Input Name for Your Community!');
      }else{
        try{
          setBeNodeStatus(1)
          const _price = (0.001)*((10**9));
          const contractCallGasLimit = 900_000;
          let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
          let result = await myContract.functions.become_node(inputValue).callParams({
            forward: [_price, baseAssetId],
            // gasLimit: contractCallGasLimit,
          }).call();
          let res = await result.waitForResult();
          if(res.transactionResult.status === 'success'){
            // setMintMonsterStatus(0);
            resetInfo();
            setBeNodeStatus(0);
          }else{
            // setMintStatus(3);
            alert('Transaction Failed');

          }
          setBeNodeStatus(0);
        }catch(e){
          alert(e.toString());
          setBeNodeStatus(0);
        }
      }

    }else{
      alert('Please Connect Wallet First!')
    }
  }

  function displayInvite(){
    setAlertRecordInviteStatus(1);
  }
  function displayCommunity(){
    setAlertRecordCommunityStatus(1);
  }


  useEffect ( ()=>{
    if(address.length>0){
      dealInit();
      // console.log(info)
    }
  },[info, address]);
    
    return(
      <div className='alert_con'>
        <div className='alert_feed_child'>  
          <div className='alert_feed_title'><img src={close_img} onClick={closePage}/></div>   
          <div className='alert_profile_title'>
            <li>Community</li>
          </div>        
          <div className='alert_community_contain'>
          <div className='alert_feed_title'><img src={record_img} onClick={displayInvite}/></div>  
            <div className='alert_community_content'>
              <div className='alert_community_img'>
                <img src={invite_img}/>
              </div>
              <div className='alert_community'>
                <div className='alert_community_invite'>
                  <li className='alert_invite_name'>Invitation Numbers: {inviteNum}</li>
                </div>
                <div className='alert_community_invite'>
                {inviteEligible === 1 ? <div className='alert_invite_url'><li className='alert_invite_title'>Inviting: </li>https://app.constellation.fun/?ref={HandString(address)} <img className='alert_invite_copy' src={copy_img} onClick={handCopy}/></div> : ''} 
                </div>
              </div>
            </div>

          </div>
          <div className='alert_community_node_contain'>
          <div className='alert_feed_title'><img src={record_img} onClick={displayCommunity}/></div>  
            <div className='alert_community_content'>
              <div className='alert_community_img'>
                <img src={community_img}/>
              </div>
              <div className='alert_community'>
                <div className='alert_community_invite'>
                  <li className='alert_invite_name'>Community Members: {nodeMemberNum}</li>
                  <li className='alert_invite_name'>Community Level: {nodeLevel}</li>
                </div>
                <div className='alert_community_node'>
                  {nodeEligible === 0 ? 
                    <div className='alert_community_benode_contain'>
                      <div className='alert_community_benode_info_contain'>
                        <li className='alert_community_price'>Price: 0.001 ETH</li >
                        <input placeholder='Input Community Name'
                              onChange={e => setInputValue(e.target.value)}/>
                      </div>
                      {beNodeStatus === 0 ? <li className='alert_community_benode' onClick={beNode}>Become Community Node</li> : <div className='alert_community_benode'><img src={loading}/></div>}
                    </div> : <div className='alert_community_node_info'>
                      <li className='alert_community_already'>Community Name: {nodeName}</li>
                      <li className='alert_community_already_info'>*Already Community Node</li>
                      </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        {alertRecordInviteStatus === 1 ? <AlertsRecordInvite setAlertRecordInviteStatus={setAlertRecordInviteStatus} address={address}/> : ''}
        {alertRecordCommunityStatus === 1 ? <AlertsRecordCommunity setAlertRecordCommunityStatus={setAlertRecordCommunityStatus} address={address}/> : ''}
      </div>
    )
  }

  export default AlertsCommunity