import React, { useState, useEffect, useRef }  from 'react'
import '../styles/monster.css'
import monster_img from '../icons/monster-img.png'
import accelerate_img from '../icons/accelerate.png'
import community_img from '../icons/community.png'
import profile_img from '../icons/profile.png'
import fruit_img from '../icons/fruit.png'
// import aries_img1 from '../icons/constellation/aries1.png'
import aries_img2 from '../icons/constellation/aries2.png'
// import taurus_img1 from '../icons/constellation/taurus1.png'
import taurus_img2 from '../icons/constellation/taurus2.png'
// import gemini_img1 from '../icons/constellation/gemini1.png'
import gemini_img2 from '../icons/constellation/gemini2.png'
// import cancer_img1 from '../icons/constellation/cancer1.png'
import cancer_img2 from '../icons/constellation/cancer2.png'
// import leo_img1 from '../icons/constellation/leo1.png'
import leo_img2 from '../icons/constellation/leo2.png'
// import virgo_img1 from '../icons/constellation/virgo1.png'
import virgo_img2 from '../icons/constellation/virgo2.png'
// import libra_img1 from '../icons/constellation/libra1.png'
import libra_img2 from '../icons/constellation/libra2.png'
// import scorpio_img1 from '../icons/constellation/scorpio1.png'
import scorpio_img2 from '../icons/constellation/scorpio2.png'
// import sagittarius_img1 from '../icons/constellation/sagittarius1.png'
import sagittarius_img2 from '../icons/constellation/sagittarius2.png'
// import capricornus_img1 from '../icons/constellation/capricornus1.png'
import capricornus_img2 from '../icons/constellation/capricornus2.png'
// import aquarius_img1 from '../icons/constellation/aquarius1.png'
import aquarius_img2 from '../icons/constellation/aquarius2.png'
// import pisces_img1 from '../icons/constellation/pisces1.png'
import pisces_img2 from '../icons/constellation/pisces2.png'
// import zodiac_img1 from '../icons/constellation/zodiac1.png'
import zodiac_img2 from '../icons/constellation/zodiac2.png'
// import universal_img1 from '../icons/constellation/universal1.png'
import universal_img2 from '../icons/constellation/universal2.png'
import monster_bg from '../icons/monsterbg.png'
import monster_cloud from '../icons/cloud2.png'
import qiu0_img from '../icons/qiu00.png'
import qiu1_img from '../icons/qiu1.png'
import qiu2_img from '../icons/qiu2.png'
import left_img from '../icons/left.png'
import right_img from '../icons/right.png'
import left_out_img from '../icons/left_out.png'
import right_out_img from '../icons/right_out.png'
import head_card_img from '../icons/head_card.png'
import bubbles_img from '../icons/bubbles.png'
import Title from './title'
import AlertsFeed from './alertsfeed'
import AlertsAccelerate from './alertsaccelerate'
import AlertsProfile from './alertsprofile'
import AlertsCommunity from './alertscommunity'
import AlertsConstellation from './alertsConstellation'
import AlertsMint from './alertsmint'
import AlertsConstellaSwap from './alertsconstellaswap'
import AlertsZodiac from './alertsZodiac'
import { TAI64 } from 'tai64'
import loading from '../icons/loading.gif'



const Monster = ({setFuel, fuel, rebirthStatus, setRebirthStatus, setalertCONStatus, setalertRebirthStatus, resetInfo, info, address, setAddress, myContract, setMyContract, setMyWallet, myname, setMyName, tokenBalance}) => {
    const [cardIndex, setCardIndex] = useState(2);
    const [cardName1, setCardName1] = useState('Aries');
    const [cardName2, setCardName2] = useState('Taurus');
    const [cardName3, setCardName3] = useState('Gemini');
    const [cardImg1, setCardImg1] = useState(aries_img2);
    const [cardImg2, setCardImg2] = useState(taurus_img2);
    const [cardImg3, setCardImg3] = useState(gemini_img2);
    const [cardNum1, setCardNum1] = useState(0);
    const [cardNum2, setCardNum2] = useState(0);
    const [cardNum3, setCardNum3] = useState(0);
    const [claimStatus, setClaimStatus] = useState(0);
    const [doclaimcardStatus, setdoclaimcardStatus] = useState(0);
    const [geneStatus, setGeneStatus] = useState(0);
    const [dorenewGene, setDorenewGene] = useState(0);
    const [dorebirthStatus, setdorebirthStatus] = useState(0);
    const [docombineStatus, setdocombineStatus] = useState(0);
    const [claimName, setClaimName] = useState('0h:0m:0s');
    const [epochName, setEpochName] = useState('0d:0h:0m:0s');
    const [todName, setTodName] = useState('0d:0h:0m:0s');
    const [geneName, setGeneName] = useState('0d:0h:0m:0s');
    const [epoch, setEpoch] = useState(1);
    const [alertfeedStatus, setalertfeedStatus] = useState(0);
    const [alertprofileStatus, setalertprofileStatus] = useState(0);
    const [alertaccelerateStatus, setalertaccelerateStatus] = useState(0);
    const [mintButtonStatus, setMintButtonStatus] = useState(1);
    // const [mintMonsterStatus, setMintMonsterStatus] = useState(0);
    const [alertcommunityStatus, setalertcommunityStatus] = useState(0);
    const [alertConstellaStatus, setalertConstellaStatus] = useState(0);
    const [rewardName, setRewardName] = useState('');
    const [mintAlertStatus, setMintAlertStatus] = useState(0);
    const [constellaSwapAlertStatus, setConstellaSwapAlertStatus] = useState(0);
    const [collectAllStatus, setCollectAllStatus] = useState(0);
    const [chooseCardName, setChooseCardName] = useState('');
    const [chooseCardNum, setChooseCardNum] = useState(0);
    const [inviter, setInviter] = useState([]);
    const [alertZodiacStatus, setalertZodiacStatus] = useState(0);


    let timer=useRef();     

    function goLeft() {
        if(cardIndex > 4){
            dealIndex(cardIndex - 3);
            setCardIndex(cardIndex - 3);
        }else if(cardIndex == 4){
            dealIndex(cardIndex - 2);
            setCardIndex(cardIndex - 2);
        }else if(cardIndex == 3){
            dealIndex(cardIndex - 1);
            setCardIndex(cardIndex - 1);
        }
    }

    function goRight(){
        if(cardIndex < 11){
            dealIndex(cardIndex + 3);
            setCardIndex(cardIndex + 3);
        }else if(cardIndex == 11){
            dealIndex(cardIndex + 2);
            setCardIndex(cardIndex + 2);
        }else if(cardIndex == 12){
            dealIndex(cardIndex + 1);
            setCardIndex(cardIndex + 1);
        }
    }

    function doSetCard(num1, num2, num3){
        
            if(num1>0){
                setCardNum1(num1);
                // setCardImg1(img1);
            }
            if(num2>0){
                setCardNum2(num2);
                // setCardImg2(img2);
            }
            if(num3>0){
                setCardNum3(num3);
                // setCardImg3(img3);
            }
    }

    function dealIndex(_index){
        if(_index == 2){
            setCardImg1(aries_img2);
            setCardImg2(taurus_img2);
            setCardImg3(gemini_img2);
            setCardName1('Aries');
            setCardName2('Taurus');
            setCardName3('Gemini');
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].aries, info[2].taurus, info[2].gemini);
                    }
                }
            }
        }else if(_index == 3){
            setCardName1('Taurus');
            setCardName2('Gemini');
            setCardName3('Cancer');
            setCardImg1(taurus_img2);
            setCardImg2(gemini_img2);
            setCardImg3(cancer_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].taurus, info[2].gemini, info[2].cancer);
                    }
                }
            }
        }else if(_index == 4){
            setCardName1('Gemini');
            setCardName2('Cancer');
            setCardName3('Leo');
            setCardImg1(gemini_img2);
            setCardImg2(cancer_img2);
            setCardImg3(leo_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].gemini, info[2].cancer, info[2].leo);
                    }
                }
            }
        }else if(_index == 5){
            setCardName1('Cancer');
            setCardName2('Leo');
            setCardName3('Virgo');
            setCardImg1(cancer_img2);
            setCardImg2(leo_img2);
            setCardImg3(virgo_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].cancer, info[2].leo, info[2].virgo);
                    }
                }
            }
        }else if(_index == 6){
            setCardName1('Leo');
            setCardName2('Virgo');
            setCardName3('Libra');
            setCardImg1(leo_img2);
            setCardImg2(virgo_img2);
            setCardImg3(libra_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].leo, info[2].virgo, info[2].libra);
                    }
                }
            }
        }else if(_index == 7){
            setCardName1('Virgo');
            setCardName2('Libra');
            setCardName3('Scorpio');
            setCardImg1(virgo_img2);
            setCardImg2(libra_img2);
            setCardImg3(scorpio_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].virgo, info[2].libra, info[2].scorpio);
                    }
                }
            }
        }else if(_index == 8){
            setCardName1('Libra');
            setCardName2('Scorpio');
            setCardName3('Sagittarius');
            setCardImg1(libra_img2);
            setCardImg2(scorpio_img2);
            setCardImg3(sagittarius_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].libra, info[2].scorpio, info[2].sagittarius);
                    }
                }
            }
        }else if(_index == 9){
            setCardName1('Scorpio');
            setCardName2('Sagittarius');
            setCardName3('Capricornus');
            setCardImg1(scorpio_img2);
            setCardImg2(sagittarius_img2);
            setCardImg3(capricornus_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].scorpio, info[2].sagittarius, info[2].capricornus);
                    }
                }
            }
        }else if(_index == 10){
            setCardName1('Sagittarius');
            setCardName2('Capricornus');
            setCardName3('Aquarius');
            setCardImg1(sagittarius_img2);
            setCardImg2(capricornus_img2);
            setCardImg3(aquarius_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].sagittarius, info[2].capricornus, info[2].aquarius);
                    }
                }
            }
        }else if(_index == 11){
            setCardName1('Capricornus');
            setCardName2('Aquarius');
            setCardName3('Pisces');
            setCardImg1(capricornus_img2);
            setCardImg2(aquarius_img2);
            setCardImg3(pisces_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].capricornus, info[2].aquarius, info[2].pisces);
                    }
                }
            }
        }else if(_index == 12){
            setCardName1('Aquarius');
            setCardName2('Pisces');
            setCardName3('Zodiac');
            setCardImg1(aquarius_img2);
            setCardImg2(pisces_img2);
            setCardImg3(zodiac_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                        if(info[2]){
                        doSetCard(info[2].aquarius, info[2].pisces, info[2].zodiac);
                    }
                }
            }
        }else if(_index == 13){
            setCardName1('Pisces');
            setCardName2('Zodiac');
            setCardName3('Universal');
            setCardImg1(pisces_img2);
            setCardImg2(zodiac_img2);
            setCardImg3(universal_img2);
            setCardNum1(0);
            setCardNum2(0);
            setCardNum3(0);
            if(address.length > 0){
                if(info.length>0){  
                    // if(info[2] != 'None'){
                    if(info[2]){
                        doSetCard(info[2].pisces, info[2].zodiac, info[2].universal);
                    }
                }
            }
        }
    }
     
    const countDown = (expiretime, types) => {
        const nowTime = +new Date(); //获取當前时间的时间戳（单位毫秒）
        const times = parseInt(`${(expiretime - nowTime) / 1000}`); //把剩余时间毫秒数转化为秒
        if(times > 0){
            let d = parseInt(`${(times / 60 / 60 ) / 24}`); //计算天数 转化为整数
            let h = parseInt(`${(times / 60 / 60) % 24}`); //计算小时数 转化为整数
            let m = parseInt(`${(times / 60) % 60}`); //计算分钟数 转化为整数
            let s = parseInt(`${times % 60}`); //计算描述 转化为整数
        
            // console.log('d:', d, 'h:', h, 'm:', m, 's:', s) 
            if(d < 10){
            d = `0${d}`
            }
            if(h < 10){
            h = `0${h}`
            }
            if(m < 10){
            m = `0${m}`
            }
            if(s < 10){
            s = `0${s}`
            }
            let times_ = d + "d : " + h + "h : " + m + "m : " + s + "s";

            if (types === 1 || types === 4){
                times_ = h + "h : " + m + "m : " + s + "s";
            }
        
        
            if(types === 1){
                // console.log(times_, types)
                setClaimName(times_)
            }else if(types ===2){
                // console.log(times_, types)
                setEpochName(times_)
            }else if(types ===3){
                // console.log(times_, types)
                setTodName(times_)
            }else if(types ===4){
                // console.log(times_, types)
                setGeneName(times_)
            }
            if(times < 3 && types === 1){
                // console.log('times:', times);
                setTimeout(() => {
                    setClaimStatus(1);    
                }, 1000);
            }
            if(times < 3 && types ===3){
                // console.log('times:', times);
                setTimeout(() => {
                    setRebirthStatus(1);
                }, 1000);
            }
            if(times < 3 && types ===4){
                // console.log('times:', times);
                setTimeout(() => {
                    setGeneStatus(1);
                }, 1000);
            }
        }else{
            
            if(types === 1){
                
                if(times > -3){
                    // console.log(claimStatus);
                    setTimeout(() => {
                        setClaimStatus(1)    
                    }, 1000);
                }
                
            }else if(types === 3){
                
                if(times > -3){
                    // console.log(rebirthStatus)
                    setTimeout(() => {
                        setRebirthStatus(1)  
                    }, 1000);
                }
                
            }else if(types === 4){
                
                if(times > -3){
                    // console.log(geneStatus)
                    setTimeout(() => {
                        setGeneStatus(1)  
                    }, 1000);
                }
                
            }
        }
        
    }

    async function claimCard(){
        if(address.length > 0){
            if(rebirthStatus == 1){
                setalertRebirthStatus(1);
            }else{
                try{
                    // console.log('here1')
                    const contractCallGasLimit = 500_000;
                    setdoclaimcardStatus(1);
                    let result = await myContract.functions.claim_constellation().callParams(
                        {
                            // gasLimit: contractCallGasLimit,
                        }
                    ).call();
                    let res = await result.waitForResult();
                    // console.log(res);
                    // console.log(res.value.toString());
                    if(res.transactionResult.status === 'success'){
                        resetInfo();
                        setdoclaimcardStatus(0);
                        setRewardName(res.value.toString());
                        setalertConstellaStatus(1);
                        // setClaimStatus(0);
                        //info里面的时间可能没有更新
                        // window.location.reload();
                    }else{
                        alert('Transaction Failed');
                        setdoclaimcardStatus(0);
        
                    }
                }catch(e){
                    alert(e.toString());
                    setdoclaimcardStatus(0);
                }
            }
            
          }else{
            alert('Please Connect Wallet First');
          }
    }
    async function doCombine(){
        if(address.length > 0){
            // if(info[2] != 'None'){
            if(info[2]){
                if(rebirthStatus == 1){
                    setalertRebirthStatus(1);
                }else{
                    if(tokenBalance < 500){
                        setalertCONStatus(1);
                    }else{
                        if(info[2].aries.toString() > 0 && info[2].taurus.toString()>0 && info[2].gemini.toString()>0 && info[2].cancer.toString()>0 && info[2].leo.toString()>0 && info[2].virgo.toString()>0 && info[2].libra.toString()>0 && info[2].scorpio.toString()>0 && info[2].sagittarius.toString()>0 && info[2].capricornus.toString()>0 && info[2].aquarius.toString()>0 && info[2].pisces.toString()>0){
                            try{
                                // console.log('here1')
                                setdocombineStatus(1);
                                const contractCallGasLimit = 750_000;
                                const _price = (500)*((10**9));
                                let assetId = '0x6e8efa8e5fde3e677a0fdfe1f53e7fc077c19627ff447a0ff7254795dd908e41'; //token assetid
                                let result = await myContract.functions.combine_constellation().callParams(
                                    {
                                        forward: [_price, assetId],
                                        // gasLimit: contractCallGasLimit,
                                    }
                                ).call();
                                // console.log(result.transactionResult);
                                let res = await result.waitForResult();
                                // console.log(res);
                                // console.log(result.transactionResult);
                                if(res.transactionResult.status === 'success'){
                                    setCollectAllStatus(0);
                                    setalertZodiacStatus(1);
                                    resetInfo();
                                    // setdocombineStatus(0);
                                }else{
                                    // setdoclaimcardStatus(0);
                                    alert('Transaction Failed');
                    
                                }
                                setdocombineStatus(0);
                            }catch(e){
                                // console.log(e);
                                // setdoclaimcardStatus(0);
                                alert(e.toString());
                                setdocombineStatus(0);
                            }
                            
                        }else{
                            alert('You Should Have Enough Constellation Cards');
                        }
                    }
                }
            }else{
                alert('You Have 0 Constellation Card');
            }
    
          }else{
            alert('Please Connect Wallet First');
          }

    }

    async function reBirth(){
        if(address.length > 0){
            try{
                // console.log('here1')
                setdorebirthStatus(1);
                const contractCallGasLimit = 650_000;
                const _price = (0.0003)*((10**9));
                let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
                let result = await myContract.functions.rebirth().callParams({
                  forward: [_price, baseAssetId],
                //   gasLimit: contractCallGasLimit,
                }).call();
                let res = await result.waitForResult();
                // console.log(res);
                // console.log(result.transactionResult);
                if(res.transactionResult.status === 'success'){
                    setdorebirthStatus(0);
                    resetInfo();
                    // navigate('/');
                    // window.location.reload();
                }else{
                    alert('Transaction Failed');
                    setdorebirthStatus(0);
    
                }
            }catch(e){
                alert(e.toString());
                setdorebirthStatus(0);
            }
    
          }else{
            alert('Please Connect Wallet First');
          }
    }

    async function doingRenewGene(){
        if(address.length > 0){
          if(info.length>0){   
            // if(info[0] != 'None'){
              if(info[0]){
                if(rebirthStatus == 1){
                    setalertRebirthStatus(1);
                }else{
                    try{
                      setDorenewGene(1);
                      let _price = ((0.0003).toFixed(4))*((10**9));
                      const contractCallGasLimit = 650_000;
                      // console.log(_price);
                      // let amount = buyNum*((10**9));
                      let baseAssetId = '0xf8f8b6283d7fa5b672b530cbb84fcccb4ff8dc40f8176ef4544ddb1f1952ad07';
                      let result = await myContract.functions.regenerate_gene().callParams({
                        forward: [_price, baseAssetId],
                        // gasLimit: contractCallGasLimit,
                      }).call();
                      let res = await result.waitForResult();
                      if(res.transactionResult.status === 'success'){
                        // getTokenBalance();
                        resetInfo();
                      }else{
                        alert('Transaction Failed');
                      }
                      setDorenewGene(0);
                    }catch(e){
                        alert(e.toString());
                        setDorenewGene(0);
                    }
                }
                
            }else{
              alert('Please Mint Monster First!');
            }
          }else{
            alert('Please Mint Monster First!');
          }
        }else{
          alert('Please Connect Wallet First!');
        }
      }

    function checkStatus(_claimtime, _todtime, _genetime){
        const nowTime = +new Date();
        if(_claimtime -nowTime > 0){
            // setTimeout(() => {
                setClaimStatus(0)  
            // }, 0);
        }else{
            // setTimeout(() => {
                setClaimStatus(1)  
            // }, 0);
        }
        if(_todtime - nowTime > 0){
            // setTimeout(() => {
                setRebirthStatus(0)  
            // }, 0);
        }else{
            // setTimeout(() => {
                setRebirthStatus(1)  
            // }, 0);
        }
        if(_genetime - nowTime > 0){
            // setTimeout(() => {
                setGeneStatus(0)  
            // }, 0);
        }else{
            // setTimeout(() => {
                setGeneStatus(1)  
            // }, 0);
        }
        if(info[2]){
            if(info[2].aries>0 && info[2].taurus > 0 && info[2].gemini > 0 && info[2].cancer > 0 && info[2].leo >0 && info[2].virgo > 0 && info[2].libra > 0 && info[2].scorpio > 0 && info[2].sagittarius > 0 && info[2].capricornus > 0 && info[2].aquarius > 0 && info[2].pisces > 0){
                setCollectAllStatus(1);
            }
        }

    }

    function displayFeed(){
        setalertfeedStatus(1);
    }
    function displayAccelerate(){
        setalertaccelerateStatus(1);
    }
    function displayGene(){
        setalertprofileStatus(1);
    }
    function displayCommunity(){
        setalertcommunityStatus(1);
    }
    function displayMint(){
        if(address.length > 0){
            setMintAlertStatus(1);
        }else{
            alert('Please Connect Wallet First');
        }
    }
    function disPlayCardSwap(data1, data2){
        if(data2 > 0 && data1 !== 'Zodiac'){
            setConstellaSwapAlertStatus(1);
            setChooseCardName(data1);
            setChooseCardNum(data2);
        }
        
        // console.log(data1, data2)
    }

    useEffect ( ()=>{
        // console.log(info);  
        if(address.length > 0){
            const searchParams = new URLSearchParams(window.location.search);
            let _inviter = searchParams.get('ref');
            // console.log(_inviter)
            if(_inviter && _inviter.length ==66){
                setInviter(_inviter)
            }else{
                setInviter('0xfe003f090b885be6f27991fef66d7d4bc0aeac94fa6dea12ab6c6eb84e471f34')
            }
            if(info.length > 0){
                if(!info[0]){
                    setMintButtonStatus(1);
                }else{
                    setMintButtonStatus(0);
                }
                if(info[0]){
                    dealIndex(cardIndex); 
                    let epochtime = ((TAI64.fromHexString((info[4].epoch_time).toString(16)).toUnix()) + 60*60*24*3)*1000;
                    let todtime=(TAI64.fromHexString((info[0].expiry).toString(16)).toUnix())*1000;
                    let claimtime=((TAI64.fromHexString((info[0].cardtime).toString(16)).toUnix()) + 60*60*6)*1000;
                    let genetime=((TAI64.fromHexString((info[0].genetime).toString(16)).toUnix()) + 60*60*6)*1000;
                    checkStatus(claimtime, todtime, genetime);
                    setEpoch(info[4].epoch.toString());
                    timer.current = setInterval(()=>{
                        countDown(claimtime, 1);
                        countDown(epochtime, 2);
                        countDown(todtime, 3);
                        countDown(genetime, 4);
                    }, 1000);
                }else{
                    dealIndex(cardIndex); 
                    if(info[4]){
                        if(info[4].epoch.toString() > 0){
                            let epochtime = ((TAI64.fromHexString((info[4].epoch_time).toString(16)).toUnix()) + 60*60*24*3)*1000;
                            setEpoch(info[4].epoch.toString());
                            timer.current = setInterval(()=>{
                                countDown(epochtime, 2);
                            }, 1000);
                        }
                    }
                    
                }
            }
        }

        return()=>{
            // console.log('leave monster page------------');
            clearInterval(timer.current);
          }
        
      }, [address, info]);

    return (
        <div className='monster_page'>
            <div className='monster_contain'>
                <Title setFuel={setFuel} fuel={fuel} address={address} setAddress={setAddress} setMyContract={setMyContract} setMyWallet={setMyWallet} myname={myname} setMyName={setMyName}/>
                <div className='monster_content_top'>
                    <div className='monster_content_top_left'><div className='monster_content_top_left_out'><li>Epoch{epoch} {epochName}</li></div></div>
                    <div className='monster_content_top_right'>
                        <div className='monster_content_top_right_out'>
                        {claimStatus == 0 ? <li className='monster_content_top_right_label'>Claim In {claimName}</li>:(doclaimcardStatus == 0 ? <li className='monster_content_top_right_button' onClick={claimCard}>Claim Constellation</li>: <li className='monster_content_top_right_button' ><img src={loading}/></li>)}
                        </div>
                    </div>
                </div>
                <img className='monster_page_qiu0' src={qiu0_img}/>
                <div className='monster_content'>
                    <div className='monster_content_left'>
                        <img src={qiu1_img}/>
                    </div>
                    <div className='monster_content_mid'>
                        <img className='monster_content_left_bg' src={monster_bg}/>
                        <img className='monster_content_left_bg2' src={monster_cloud}/>
                        {/* <img className='monster_head_card_img' src={head_card_img}/> */}
                        <img className='monster_bubble_card_img' src={bubbles_img}/>
                        <img className='monster_content_left_monster' src={monster_img}/>
                    </div>
                    <div className='monster_content_right'>
                        <img src={qiu2_img}/>
                    </div>
                </div>
                <div className='monster_content_bottom'>
                    <div className='monster_content_top_left'>
                        <div className='monster_content_top_left_out'>
                            {mintButtonStatus === 1 ? (<div className='monster_mint_button' onClick={displayMint}>Mint Monster</div>):(rebirthStatus === 0 ? (<li className='monster_tod_label'>TOD {todName}</li>):(dorebirthStatus == 0 ? <div className='monster_tod_button' onClick={reBirth}>0.0003ETH to Rebirth</div>: <div className='monster_tod_button' ><img src={loading}/></div>))}
                        </div>
                            {/* <div className='monster_content_top_left_out'><li>Epoch{epoch} {epochName}</li></div> */}
                    </div>
                    <div className='monster_content_top_right'>
                        <div className='monster_content_top_right_out'>
                        {geneStatus == 0 ? <li className='monster_content_top_right_label'>New Gene In {geneName}</li>:(dorenewGene == 0 ? <li className='monster_content_top_right_button' onClick={doingRenewGene}>0.0003ETH to Renew Gene</li>: <li className='monster_content_top_right_button' ><img src={loading}/></li>)}
                        </div>
                    </div>
                </div>
                <div className='monster_content_item'>
                    <div className='monster_content_item_contain'>
                        <div className='monster_accelerate' onClick={displayCommunity}>
                            <li>COMMUNITY</li>
                            <img className='monster_right_img_community' src={community_img} />
                        </div>
                        <div className='monster_accelerate' onClick={displayAccelerate}>
                            <li>ACCELERATE</li>
                            <img className='monster_right_img' src={accelerate_img} />
                            
                        </div>
                    </div>
                    <div className='monster_content_item_contain'>
                        <div className='monster_accelerate' onClick={displayFeed}>
                            <li>FEED</li>
                            <img className='monster_right_img' src={fruit_img}/>
                            
                        </div>
                        <div className='monster_accelerate' onClick={displayGene}>
                            <li>PROFILE</li>
                            <img className='monster_right_img' src={profile_img}/>
                            
                        </div>
                    </div>    
                </div>
                <div className='monster_card_contain_out'>
                    <img className='monster_card_contain_left' src={left_out_img}/>
                    <div className='monster_card'>
                        <div className='monster_card_left_right' onClick={goLeft}>
                            <img src={left_img} />
                        </div>
                        <div className='monster_card_contrain' onClick={()=>disPlayCardSwap(cardName1, cardNum1)}>
                            {/* {cardNum1 === 0 ? (<div className='card_title_none'><li></li></div>):(<div className='card_title'><li>x{cardNum1}</li></div>)} */}
                           
                            {/* <div className='monster_card_content2'> */}
                                <img src={cardImg1}/>
                                {/* {cardNum1 === 0 ? (<li className='monster_card_name1'>{cardName1}</li>) : (<li className='monster_card_name2'>{cardName1}</li>)} */}
                                {/* <li className='monster_card_name2'>{cardName1}</li> */}
                            
                            <div className='monster_card_num'>
                                <li className='monster_card_num_left'></li>
                                <li className='monster_card_num_mid'>x{cardNum1}</li>
                                <li className='monster_card_num_left'></li>
                            </div>
                        </div>
                        <div className='monster_card_contrain' onClick={()=>disPlayCardSwap(cardName2, cardNum2)}>
                            {/* {cardNum2 === 0 ? (<div className='card_title_none'><li></li></div>):(<div className='card_title'><li>x{cardNum2}</li></div>)} */}
                            {/* <div className='monster_card_content2'> */}
                                <img src={cardImg2}/>
                                {/* <li className='monster_card_name2'>{cardName2}</li> */}
                            {/* </div> */}
                            <div className='monster_card_num'>
                                <li className='monster_card_num_left'></li>
                                <li className='monster_card_num_mid'>x{cardNum2}</li>
                                <li className='monster_card_num_left'></li>
                            </div>
                        </div>
                        <div className='monster_card_contrain' onClick={()=>disPlayCardSwap(cardName3, cardNum3)}>
                            {/* {cardNum3 === 0 ? (<div className='card_title_none'><li></li></div>):(<div className='card_title'><li>x{cardNum3}</li></div>)} */}
                            {/* <div className='monster_card_content2'> */}
                                <img src={cardImg3}/>
                                {/* <li className='monster_card_name2'>{cardName3}</li> */}
                            {/* </div> */}
                            <div className='monster_card_num'>
                                <li className='monster_card_num_left'></li>
                                <li className='monster_card_num_mid'>x{cardNum3}</li>
                                <li className='monster_card_num_left'></li>
                            </div>
                        </div>
                        <div className='monster_card_left_right' onClick={goRight}>
                            <img src={right_img} />
                        </div>
                        
                    </div>
                    <img className='monster_card_contain_right' src={right_out_img}/>
                </div>
                
                <div className='monster_combine'>
                   {docombineStatus === 0 ? <li className='monster_combine_button' onClick={doCombine}>500CON to Combine</li> : <li className='monster_combine_button'><img src={loading}/></li>}
                </div>

            </div>
            {alertfeedStatus === 0 ? '' : <AlertsFeed rebirthStatus={rebirthStatus} setalertRebirthStatus={setalertRebirthStatus} setalertfeedStatus={setalertfeedStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
            {alertaccelerateStatus === 0 ? '' : <AlertsAccelerate rebirthStatus={rebirthStatus} setalertRebirthStatus={setalertRebirthStatus} setalertaccelerateStatus={setalertaccelerateStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} />}
            {alertprofileStatus === 0 ? '' : <AlertsProfile rebirthStatus={rebirthStatus} setalertRebirthStatus={setalertRebirthStatus} setalertprofileStatus={setalertprofileStatus} info={info} address={address} myContract={myContract} resetInfo={resetInfo} tokenBalance={tokenBalance}/>}
            {alertcommunityStatus === 0 ? '' : <AlertsCommunity setalertprofileStatus={setalertcommunityStatus} geneStatus={geneStatus} geneName={geneName} info={info} address={address} myContract={myContract} resetInfo={resetInfo} tokenBalance={tokenBalance}/>}
            {alertConstellaStatus === 0 ? '' : <AlertsConstellation setalertConstellaStatus={setalertConstellaStatus} rewardName={rewardName}/>}
            {mintAlertStatus === 0 ? '' : <AlertsMint setMintAlertStatus={setMintAlertStatus} inviter={inviter} address={address} myContract={myContract} resetInfo={resetInfo}/>}
            {constellaSwapAlertStatus === 0 ? '' : <AlertsConstellaSwap rebirthStatus={rebirthStatus} setalertRebirthStatus={setalertRebirthStatus} setConstellaSwapAlertStatus={setConstellaSwapAlertStatus} address={address} myContract={myContract} resetInfo={resetInfo} chooseCardName={chooseCardName} chooseCardNum={chooseCardNum}/>}
            {alertZodiacStatus === 0 ? '' : <AlertsZodiac setalertZodiacStatus={setalertZodiacStatus}/>}
        </div>
        
        
    )

}

export default Monster