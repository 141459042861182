import React, { useEffect, useState} from 'react'
import '../styles/alertsbattle.css'
import close_img from '../icons/close.png'
import battle_img from '../icons/battle.png'
import loading from '../icons/loading.gif'
import aries_img2 from '../icons/constellation/aries1.png'
import taurus_img2 from '../icons/constellation/taurus1.png'
import gemini_img2 from '../icons/constellation/gemini1.png'
import cancer_img2 from '../icons/constellation/cancer1.png'
import leo_img2 from '../icons/constellation/leo1.png'
import virgo_img2 from '../icons/constellation/virgo1.png'
import libra_img2 from '../icons/constellation/libra1.png'
import scorpio_img2 from '../icons/constellation/scorpio1.png'
import sagittarius_img2 from '../icons/constellation/sagittarius1.png'
import capricornus_img2 from '../icons/constellation/capricornus1.png'
import aquarius_img2 from '../icons/constellation/aquarius1.png'
import pisces_img2 from '../icons/constellation/pisces1.png'


const AlertsBattle = ({rebirthStatus, tokenBalance, setalertCONStatus, setalertRebirthStatus, setalertbattleStatus, gene, weight, constella, listid, info, address, myContract, resetInfo}) =>{
  const [myWeight, setMyWeight] = useState(0);
  const [myGene, setMyGene] = useState('0');
  const [cardValue, setCardValue] = useState(1);
  const [ariesNum, setAriesNum] = useState(0);
  const [taurusNum, setTaurusNum] = useState(0);
  const [geminiNum, setGeminiNum] = useState(0);
  const [cancerNum, setCancerNum] = useState(0);
  const [leoNum, setLeoNum] = useState(0);
  const [virgoNum, setVirgoNum] = useState(0);
  const [libraNum, setLibraNum] = useState(0);
  const [scorpioNum, setScorpioNum] = useState(0);
  const [sagittariusNum, setSagittariusNum] = useState(0);
  const [capricornusNum, setCapricornusNum] = useState(0);
  const [aquariusNum, setAquariusNum] = useState(0);
  const [piscesNum, setPiscesNum] = useState(0);
  const [doBattleStatus, setdoBattleStatus] = useState(0);
  const [winStatus, setWinStatus] = useState(0);
  const [resultStatus, setResultStatus] = useState(0);



  function closePage(){
    setalertbattleStatus(0);
  }

  function HandString(str){
    let length = str.length;
    if(str.length < 7){
      return str
    }else{
        let pre = str.substr(0,3);
        let end = str.substr(length - 3, 3);
        return pre+'...'+ end;
    }
    // let pre = str.substr(0,5);
    
  }

  function handerCardChange(e){
    // console.log(e.target.value)
    setCardValue(e.target.value);
  }

  function getImg(data){
    if(data == 1){
      return aries_img2
    }else if(data == 2){
      return taurus_img2
    }else if(data == 3){
      return gemini_img2
    }else if(data == 4){
      return cancer_img2
    }else if(data == 5){
      return leo_img2
    }else if(data == 6){
      return virgo_img2
    }else if(data == 7){
      return libra_img2
    }else if(data == 8){
      return scorpio_img2
    }else if(data == 9){
      return sagittarius_img2
    }else if(data == 10){
      return capricornus_img2
    }else if(data == 11){
      return aquarius_img2
    }else if(data == 12){
      return pisces_img2
    // }else if(data == 'Aries'){
    //   return aries_img2
    }
  }

  function getName(data){
    if(data == 1){
      return 'Aries'
    }else if(data == 2){
      return 'Taurus'
    }else if(data == 3){
      return 'Gemini'
    }else if(data == 4){
      return 'Cancer'
    }else if(data == 5){
      return 'Leo'
    }else if(data == 6){
      return 'Virgo'
    }else if(data == 7){
      return 'Libra'
    }else if(data == 8){
      return 'Scorpio'
    }else if(data == 9){
      return 'Sagittarius'
    }else if(data == 10){
      return 'Capricornus'
    }else if(data == 11){
      return 'Aquarius'
    }else if(data == 12){
      return 'Pisces'
    // }else if(data == 'Aries'){
    //   return 'Aries'
    }
  }


  function getMyInfo(){
    // if(info[0] != 'None'){
      if(info[0]){
      setMyGene(info[0].gene.toString());
      setMyWeight(info[0].bonus.toString());
    }
  }

  function getConstellationNum(){
    // if(info[2] != 'None'){
      if(info[2]){
       setAriesNum(info[2].aries.toString());
       setTaurusNum(info[2].taurus.toString());
       setGeminiNum(info[2].gemini.toString());
       setCancerNum(info[2].cancer.toString());
       setLeoNum(info[2].leo.toString());
       setVirgoNum(info[2].virgo.toString());
       setLibraNum(info[2].libra.toString());
       setScorpioNum(info[2].scorpio.toString());
       setSagittariusNum(info[2].sagittarius.toString());
       setCapricornusNum(info[2].capricornus.toString());
       setAquariusNum(info[2].aquarius.toString());
       setPiscesNum(info[2].pisces.toString());
    }

  }

  async function doingBattle(){
    if(rebirthStatus == 1){
      setalertRebirthStatus(1);
    }else{
      if(tokenBalance < 100){
        setalertCONStatus(1);
      }else{
        try{
          // console.log(listid);
          setdoBattleStatus(1);
          const _price = (100)*((10**9));
          const contractCallGasLimit = 950_000;
          let assetId = '0x6e8efa8e5fde3e677a0fdfe1f53e7fc077c19627ff447a0ff7254795dd908e41'; //token assetid
          let result = await myContract.functions.battle(cardValue, listid).callParams(
            {
              forward: [_price, assetId],
              // gasLimit: contractCallGasLimit,
            }
          ).call();
          let res = await result.waitForResult();
          if(res.transactionResult.status === 'success'){
            // setMintMonsterStatus(0);
            setTimeout(() => {
              resetInfo();//等待10秒再执行
            }, 10*1000);
            
            // console.log(res.value);
            setWinStatus(1)
            if(res.value){
              setResultStatus(1)
            }
          }else{
            // setMintStatus(3);
            alert('Transaction Failed');
    
          }
          setdoBattleStatus(0);
        }catch(e){
          console.log(e);
          alert(e.toString());
          setdoBattleStatus(0);
        }
      }
    }
  }

  function doBattle(){
    if(address.length > 0){
      if(info.length > 0){
        // if(info[2] != 'None'){
          if(info[2]){
          // console.log(listid);
          if(cardValue == 1){
            if(info[2].aries.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Aries Card');
            }
          }else if(cardValue == 2){
            if(info[2].taurus.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Taurus Card');
            }
          }else if(cardValue == 3){
            if(info[2].gemini.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Gemini Card');
            }
          }else if(cardValue == 4){
            if(info[2].cancer.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Cancer Card');
            }
          }else if(cardValue == 5){
            if(info[2].leo.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Leo Card');
            }
          }else if(cardValue == 6){
            if(info[2].virgo.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Virgo Card');
            }
          }else if(cardValue == 7){
            if(info[2].libra.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Libra Card');
            }
          }else if(cardValue == 8){
            if(info[2].scorpio.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Scorpio Card');
            }
          }else if(cardValue == 9){
            if(info[2].sagittarius.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Sagittarius Card');
            }
          }else if(cardValue == 10){
            if(info[2].capricornus.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Capricornus Card');
            }
          }else if(cardValue == 11){
            if(info[2].aquarius.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Aquarius Card');
            }
          }else if(cardValue == 12){
            if(info[2].pisces.toString() > 0){
              doingBattle();
            }else{
              alert('Your Have 0 Pisces Card');
            }
          }
        
        }else{
          alert('You Should Have 1 Card');
        }

      }
    }
  }

  useEffect ( ()=>{
    if(address.length > 0){
      if(info.length > 0){
        getMyInfo();
        getConstellationNum();
        }
    }
  }, [address]);
    
    return(
      <div className='alert_con'>
        <div className='alert_battle_child'>  
          <div className='alert_list_title'><img src={close_img} onClick={closePage}/></div>   
          <div className='alert_battle_title'>
            <div className='alert_battle_choose_left'><li>Opponent</li></div>
            <div className='alert_battle_choose_mid'></div>
            <div className='alert_battle_choose_right'><li>You</li></div>
          </div>
          <div className='alert_battle_choose'>
            <div className='alert_battle_choose_left'></div>
            <div className='alert_battle_choose_mid'></div>
            <div className='alert_battle_choose_right'>
            <select name = "dropdown" value={cardValue}
                                    onChange={(e)=>handerCardChange(e)}>
                            <option value = "1" >Aries {ariesNum}</option>
                            <option value = "2">Taurus {taurusNum}</option>
                            <option value = "3">Gemini {geminiNum}</option>
                            <option value = "4">Cancer {cancerNum}</option>
                            <option value = "5">Leo {leoNum}</option>
                            <option value = "6">Virgo {virgoNum}</option>
                            <option value = "7">Libra {libraNum}</option>
                            <option value = "8">Scorpio {scorpioNum}</option>
                            <option value = "9">Sagittarius {sagittariusNum}</option>
                            <option value = "10">Capricornus {capricornusNum}</option>
                            <option value = "11">Aquarius {aquariusNum}</option>
                            <option value = "12">Pisces {piscesNum}</option>
                        </select>
            </div>
          </div>
          <div className='alert_battle_contain'>
            <div className='alert_battle_choose_left'>
              <div className='alert_battle_left_contain'>
                <img src={getImg(constella)}/>
                <li>{getName(constella)}</li>
                <div className='alert_battle_info'>
                  <div className='alert_battle_gene'>
                    <li>Gene</li>
                    <li>{HandString(gene)}</li>
                  </div>
                  <div className='alert_battle_weight'>
                    <li>Weight</li>
                    <li>{weight}</li>
                  </div>
                </div>
              </div>
            </div>
            <div className='alert_battle_choose_mid'>
              <img src={battle_img} />
            </div>
            <div className='alert_battle_choose_right'>
              <div className='alert_battle_left_contain'>
              <img src={getImg(cardValue)}/>
                <li>{getName(cardValue)}</li>
                <div className='alert_battle_info'>
                  <div className='alert_battle_gene'>
                    <li>Gene</li>
                    <li>{HandString(myGene)}</li>
                  </div>
                  <div className='alert_battle_weight'>
                    <li>Weight</li>
                    <li>{myWeight}</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {winStatus === 1 ? (resultStatus === 0 ? <li className='alert_battle_lose'>Unfortunately, You Lose!</li> : <li className='alert_battle_win'>Congratulations, You Win!</li>)
            : <div className='alert_battle_button'><li className='battle_price'>Price: 100 CON</li>
            {doBattleStatus === 0 ? <li className='alert_battle_button_do' onClick={doBattle}>Battle</li> : <li className='alert_battle_button_do'><img src={loading}/></li>}
          </div>}
        </div>
      </div>
    )
  }

  export default AlertsBattle