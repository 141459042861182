import React, { useEffect, useState, useRef} from 'react'
import '../styles/alertsrecord.css'
import close_img from '../icons/close.png'
import axios from 'axios'


const AlertsRecordInvite = ({setAlertRecordInviteStatus, address}) =>{
  const recordRef = useRef(); //创建useRef
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setData] = useState([]);


  function closePage(){
    setAlertRecordInviteStatus(0);
  }

  const ParsTime=(time)=>{
    let timestamp = time
    // 此处时间戳以毫秒为单位
    let date = new Date(parseInt(timestamp) * 1000);
    let Year = date.getFullYear();
    let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    let  GMT =  Year + '-' + Moth + '-' + Day;
    return GMT;
  }

  function HandString(str){
    let length = str.length;
    let pre;
    if(str.length == 64){
        pre = str.substr(0,2) + '00' + str.substr(2,1);

    }else{
        pre = str.substr(0,6);
    }
    // let pre = str.substr(0,5);
    let end = str.substr(length - 5, 5);
    return pre+'...'+ end;
  }

  const getDatas = async(_pages) =>{
      
    try{
      //   console.log(epoch)
        // const res = await axios.get('http://localhost:3001/getmyinvite', {
          const res = await axios.get('https://api.constellation.fun/getmyinvite', {
            params: {
              page_num: _pages,
              page_size: 10,
              address: address
            }
        })
        // console.log(res)
        if(res.status == 200){
          if(res.data.paging.total%res.data.paging.page_size == 0){
            if(parseInt(res.data.paging.total/res.data.paging.page_size) > 0){
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size))
            }
          }else{
              setTotalPage(parseInt(res.data.paging.total/res.data.paging.page_size) + 1)
          }
          let arr = []
          for(let i = 0; i < res.data.data.length; i++){
            // arr.push({id: (res.data.paging.page_num - 1)*10 + i, winer: res.data.data[i].winer, loser: res.data.data[i].loser, winer_back: res.data.data[i].winer_back, winer_win: res.data.data[i].winer_win, loser_get: res.data.data[i].loser_get, time: res.data.data[i].time.split('T')[0]})
            arr.push({id: (res.data.paging.page_num - 1)*10 + i, user: res.data.data[i].user, epoch: res.data.data[i].epoch, time: ParsTime(res.data.data[i].time)})
          }
          if(_pages == 1){
            setData(arr)
          }else{
            setData(data.concat(arr))
          }
          // console.log(_pages);
          // console.log(data);
          
         }
      }catch(e){
        console.log(e)
      }
    }
  
    const handleScroll = () =>{
      let res=recordRef.current.scrollHeight - recordRef.current.clientHeight- recordRef.current.scrollTop;
      // console.log(res)
      if (res>1) {
            //未到底
        } else {
            //已到底部
            // console.log('to bottom');
          //   console.log(page, totalPage);
            if(page < totalPage){
              if(address.length > 0){
                getDatas(page+1)
                setPage(page+1)
              }
            }
      }
    }

  useEffect ( ()=>{
      if(address.length > 0){
            getDatas(page);
          }
      
  }, [address]);


    if(data.length > 0){
      return(
        <div className='alert_con'>
          <div className='alert_list_child'>  
            <div className='alert_list_title'><img src={close_img} onClick={closePage}/></div>    
            <div className='alert_record_contain' ref={recordRef} onScroll={handleScroll}>
              <div className='alert_record_title'>
                <li>Invite</li>
                <li>Epoch</li>
                <li>Time</li>
              </div>
              {data.map(item => <div className='alert_record_content' key={item.id}>
                <div className='alert_record_content_contain'>
                  <div className='alert_record_content'>
                    <li className='record_item'>{HandString(item.user)}</li>
                    <li className='record_item'>{item.epoch}</li>
                    <li className='record_item'>{item.time}</li>                   
                  </div>
                </div>
              </div>)}
              
            </div>
          </div>
        </div> 
      )

    }else{
      return(
        <div className='alert_con'>
          <div className='alert_list_child'> 
            <div className='alert_list_title'><img src={close_img} onClick={closePage}/></div>    
            <div className='alert_record_contain' >
              <div className='alert_record_title'>
                <li>Invite</li>
                <li>Epoch</li>
                <li>Time</li>
              </div>
              <div className='alert_record_invite_no_record' >
                  No Invite Data Yet
              </div>
            </div>
          </div>
        </div>
      )
    }

  }

  export default AlertsRecordInvite