import React, { useEffect, useState} from 'react'
import '../styles/alertscon.css'
import close_img from '../icons/close.png'



const AlertsRebirth = ({setalertRebirthStatus}) =>{

  function closePage(){
    setalertRebirthStatus(0);
  }
    
    return(
      <div className='alert_con'>
        <div className='alerts_con'>  
          <div className='alert_feed_title'><img src={close_img} onClick={closePage}/></div>   
          
            <li className='alert_con_content'>Your monster is expired<br/> please rebirth first!</li>
          
        </div>
      </div>
    )
  }

  export default AlertsRebirth