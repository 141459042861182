import React, { useEffect, useState} from 'react'
import '../styles/alertsspin.css'
import close_img from '../icons/close.png'
import loading from '../icons/loading.gif'
import aries_img2 from '../icons/constellation/aries1.png'
import taurus_img2 from '../icons/constellation/taurus1.png'
import gemini_img2 from '../icons/constellation/gemini1.png'
import cancer_img2 from '../icons/constellation/cancer1.png'
import leo_img2 from '../icons/constellation/leo1.png'
import virgo_img2 from '../icons/constellation/virgo1.png'
import libra_img2 from '../icons/constellation/libra1.png'
import scorpio_img2 from '../icons/constellation/scorpio1.png'
import sagittarius_img2 from '../icons/constellation/sagittarius1.png'
import capricornus_img2 from '../icons/constellation/capricornus1.png'
import aquarius_img2 from '../icons/constellation/aquarius1.png'
import pisces_img2 from '../icons/constellation/pisces1.png'
import zodiac_img2 from '../icons/constellation/zodiac1.png'
import universal_img2 from '../icons/constellation/universal1.png'



const AlertsConstellation = ({setalertConstellaStatus, rewardName}) =>{

  // const [cardName, setCardName] = useState('Aries');
  // const [cardImg, setCardImg] = useState(aries_img2);

  function closePage(){
    setalertConstellaStatus(0);
  }

  function getNameImg(_tpye){
    // console.log(rewardName);
    if(rewardName == 1){
      if(_tpye ===1){
        return 'Aries'
      }else{
        return aries_img2
      }
    }else if(rewardName == 2){
      if(_tpye ===1){
        return 'Taurus'
      }else{
        return taurus_img2
      }
    }else if(rewardName == 3){
      if(_tpye ===1){
        return 'Gemini'
      }else{
        return gemini_img2
      }
    }else if(rewardName == 4){
      if(_tpye ===1){
        return 'Cancer'
      }else{
        return cancer_img2
      }
    }else if(rewardName == 5){
      if(_tpye ===1){
        return 'Leo'
      }else{
        return leo_img2
      }
    }else if(rewardName == 6){
      if(_tpye ===1){
        return 'Virgo'
      }else{
        return virgo_img2
      }
    }else if(rewardName == 7){
      if(_tpye ===1){
        return 'Libra'
      }else{
        return libra_img2
      }
    }else if(rewardName == 8){
      if(_tpye ===1){
        return 'Scorpio'
      }else{
        return scorpio_img2
      }
    }else if(rewardName == 9){
      if(_tpye ===1){
        return 'Sagittarius'
      }else{
        return sagittarius_img2
      }
    }else if(rewardName == 10){
      if(_tpye ===1){
        return 'Capricornus'
      }else{
        return capricornus_img2
      }
    }else if(rewardName == 11){
      if(_tpye ===1){
        return 'Aquarius'
      }else{
        return aquarius_img2
      }
    }else if(rewardName == 12){
      if(_tpye ===1){
        return 'Pisces'
      }else{
        return pisces_img2
      }
    }else{
      if(_tpye ===1){
        return 'Aries'
      }else{
        return aries_img2
      }
    }
  }

    
    return(
      <div className='alert_con'>
        <div className='alert_list_child'>  
          <div className='alert_spin_title'><img src={close_img} onClick={closePage}/></div>   
          <div className='alert_constella_contain'>
            <li className='alert_constella_contain_title'>Congratulations!</li>
            <div className='alert_constella_contain_content'>You Got <img src={getNameImg(2)}/> {getNameImg(1)}</div>
          </div>
          
        </div>
      </div>
    )
  }

  export default AlertsConstellation